import { StyleSheet, TouchableOpacity, TouchableOpacityProps, View } from 'react-native';

import { useCart } from '@fhs/cart';
import { IconShoppingBag, Text, tokens } from '@fhs/ui';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';

export type HeaderCartButtonProps = Omit<TouchableOpacityProps, 'children'>;
export function HeaderCartButton(props: HeaderCartButtonProps) {
  const enableCartService = useFlag(LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE);
  const { cart, isPending } = useCart();
  const { numCartPreviewEntries } = useOrderContext();
  // TODO: Add a loading state to cart entries length for on initial mount
  const entries = enableCartService ? cart?.entries.length : numCartPreviewEntries;
  const isCartEmpty = entries < 1;
  return (
    <TouchableOpacity style={styles.container} disabled={isCartEmpty} {...props}>
      <View>
        <IconShoppingBag size={32} />
      </View>
      {!isPending && (
        <View
          style={[
            styles.badgeRounded,
            {
              backgroundColor: isCartEmpty
                ? tokens.colors.$houseLight
                : tokens.colors.$houseRedDarken,
            },
          ]}
        >
          <Text.Paragraph
            style={{
              color: isCartEmpty ? tokens.colors.$black : tokens.colors.$white,
            }}
            size="xs"
            weight="bold"
          >
            {entries}
          </Text.Paragraph>
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
    position: 'relative',
  },
  badgeRounded: {
    width: 20,
    height: 20,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: -10,
    right: -10,
  },
});
